import { InformationCircleIcon } from '@heroicons/react/solid';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Markdown } from '../../components/markdown';

export const LoadingHeader: React.FC = () => {
  const { t } = useTranslation();

  const [loadingPhraseId, setLoadingPhraseId] = useState(0);
  const [animationClass, setAnimationClass] = useState('');

  const data = [];

  useEffect(() => {
    const interval = setInterval(() => {
      setLoadingPhraseId((value) => {
        setAnimationClass('animation-bouncing-delay');
        setTimeout(function () {
          setAnimationClass('');
        }, 400);
        if (value < data.length - 1) {
          return value + 1;
        }
        return 0;
      });
    }, 3500);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <div className="py-20 px-8">
      <h1 className="text-3xl leading-9 font-bold">{t('tracking_page.loadingTitle')}</h1>
      <div className={`text-blue-500 flex mt-8 ${animationClass}`}>
        <InformationCircleIcon className="text-blue-500 h-6 w-6 flex-none mr-1 block" />
        <Markdown>{data[loadingPhraseId]}</Markdown>
      </div>
    </div>
  );
};

export const LoadingContent = () => (
  <div className="w-full">
    <div className="h-16 w-full bg-gray-100 rounded-xl relative overflow-hidden">
      <div className="absolute animate-loadingBackground w-1/2 h-full bg-gradient-to-r from-gray-100 via-gray-300 to-gray-100"></div>
    </div>
    <div className="h-32 w-full bg-gray-100 rounded-xl my-6 relative overflow-hidden">
      <div className="absolute animate-loadingBackground w-1/2 h-full bg-gradient-to-r from-gray-100 via-gray-300 to-gray-100"></div>
    </div>
    <div className="h-10 w-5/6 bg-gray-100 rounded-xl my-6 relative overflow-hidden">
      <div className="absolute animate-loadingBackground w-1/2 h-full bg-gradient-to-r from-gray-100 via-gray-300 to-gray-100"></div>
    </div>
    <div className="h-10 w-4/6 bg-gray-100 rounded-xl my-6 relative overflow-hidden">
      <div className="absolute animate-loadingBackground w-1/2 h-full bg-gradient-to-r from-gray-100 via-gray-300 to-gray-100"></div>
    </div>
    <div className="h-10 w-3/6 bg-gray-100 rounded-xl my-6 relative overflow-hidden">
      <div className="absolute animate-loadingBackground w-1/2 h-full bg-gradient-to-r from-gray-100 via-gray-300 to-gray-100"></div>
    </div>
  </div>
);
