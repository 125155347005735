import { format, isToday, isYesterday } from 'date-fns';
import React from 'react';
import { ILog } from './types';
import { parseDate } from '../../utils/parseDate';
import { useTranslation } from 'react-i18next';
import { dateFNSMappedLocales, customLanguageDetector } from '../../strings/setup';

export const Log: React.FC<{ logs: ILog[] }> = ({ logs }) => {
  const { t } = useTranslation();
  return (
    <div>
      <div id="dettaglio" className="-top-20 md:-top-28 block relative"></div>

      <ul className="divide-y divide-gray-200">
        {logs
          ? logs.map((log, i) => (
              <li
                key={i}
                className="relative bg-white py-5 px-4 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600"
              >
                <div className="flex justify-between items-center space-x-3">
                  <div className="md:min-w-0 md:flex-1 w-1/2 md:w-auto">
                    <span className="absolute inset-0" aria-hidden="true" />
                    <p className="text-sm font-medium text-gray-900 truncate uppercase">
                      {t(`tracking_page.status_info.${log.code}.label_short`)}
                    </p>
                    <p className="text-sm text-gray-500">{log.place}</p>
                  </div>
                  <time
                    dateTime={log.date}
                    className="flex-shrink-0 whitespace-nowrap text-xs leading-4 font-medium text-gray-500"
                  >
                    {isToday(parseDate(log.date)) ? t('tracking_page.logs.date.today_prefix') : null}
                    {isYesterday(parseDate(log.date)) ? t('tracking_page.logs.date.yesterday_prefix') : null}
                    <span className="text-gray-400 ">
                      {format(parseDate(log.date), 'dd MMMM, kk:mm', {
                        locale: dateFNSMappedLocales[customLanguageDetector()]
                      })}
                    </span>
                  </time>
                </div>
                <div className="mt-1 w-1/2 md:w-2/3">
                  <p className="line-clamp-2 text-sm text-gray-600">{log.detail}</p>
                </div>
              </li>
            ))
          : null}
      </ul>
    </div>
  );
};
