import { endOfDay, format, isBefore, isSameDay, isTomorrow, isYesterday } from 'date-fns';
import { addBusinessDays } from 'date-fns/esm';
import i18next from 'i18next';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { parseDate } from '../../utils/parseDate';
import { ShipmentModel } from '../trackingId/types';
import { dateFNSMappedLocales, customLanguageDetector } from '../../strings/setup';

export type TrackingResponse = {
  data: ShipmentModel;
};

export enum StatusType {
  NORMAL,
  PROBLEM_USER_SOLVABLE,
  PROBLEM_NOT_USER_SOLVABLE,
  PROBLEM_NOT_SOLVABLE, // Still to define
  DELIVERED_SUCCESSFULLY
}

export const estimatedArrivalText = (eta: Date) => {
  const today = new Date();
  if (isSameDay(eta, today)) {
    return (
      <>
        Consegna prevista <strong>oggi stesso</strong>
      </>
    );
  }
  if (isTomorrow(eta)) {
    return (
      <>
        Consegna prevista <strong>domani</strong>
      </>
    );
  }
  if (isYesterday(eta)) {
    return (
      <>
        Consegna prevista <strong>ieri</strong>
      </>
    );
  }
  return (
    <>
      Consegna prevista{' '}
      <strong>
        {format(eta, 'eeee d MMMM', {
          locale: dateFNSMappedLocales[customLanguageDetector()]
        })}
      </strong>
    </>
  );
};

export const deliveredText = (deliveredDate: Date) => {
  const { t } = useTranslation();

  const today = new Date();
  const dateInParenthesis = `${format(deliveredDate, 'eeee d MMMM', {
    locale: dateFNSMappedLocales[customLanguageDetector()]
  })}`;

  if (isSameDay(deliveredDate, today)) {
    return (
      <Trans i18nKey="tracking_page.delivered_text.today" values={{ dateInParenthesis }}>
        È arrivata <strong>oggi {{ dateInParenthesis }}</strong>
      </Trans>
    );
  }
  if (isYesterday(deliveredDate)) {
    return (
      <Trans i18nKey="tracking_page.delivered_text.yesterday" values={{ dateInParenthesis }}>
        È arrivata <strong>ieri {{ dateInParenthesis }}</strong>
      </Trans>
    );
  }
  return (
    <>
      {t('tracking_page.delivered_text.shipment_delivered_on')}{' '}
      <strong>
        {format(deliveredDate, 'eeee d MMMM', {
          locale: dateFNSMappedLocales[customLanguageDetector()]
        })}
      </strong>
    </>
  );
};

export type StatusInfo = {
  label: React.ReactNode;
  type: StatusType;
  deliveredDate?: Date | null;
  deliveredDateHasTime?: boolean;
  canAskForAssistancePostDelivery?: boolean;
  postDeliveryAssistanceEndDate?: Date;
  description?: string;
};

export const getStatusInfo = (t: typeof i18next.t) => ({
  // logs
  status,
  delivered_at,
  merchant,
  managed
}: ShipmentModel): StatusInfo => {
  const merchantStyleProps = merchant?.color_primary ? { style: { color: merchant.color_primary } } : {};

  // tmp variable because i have to override the `type` field if the support asked
  // the user to open a ticket, see below
  let info: StatusInfo | null = null;

  switch (status.code) {
    case '0': // CREATA
    case '10': // IN SOSPESO
    case '20': // ATTESA RITIRO
      info = {
        label: (
          <Trans i18nKey="tracking_page.status_info.20.label">
            Spedizione
            <span className={merchantStyleProps.style ? '' : `text-blue-500`} {...merchantStyleProps}>
              in attesa di ritiro
            </span>
          </Trans>
        ),
        type: StatusType.NORMAL,
        description: t('tracking_page.status_info.20.description')
      };
      break;
    case '30': // PARTITO
      info = {
        label: (
          <Trans i18nKey="tracking_page.status_info.30.label">
            Spedizione
            <span className={merchantStyleProps.style ? '' : `text-blue-500`} {...merchantStyleProps}>
              partita
            </span>
          </Trans>
        ),
        type: StatusType.NORMAL,
        description: t('tracking_page.status_info.30.description')
      };
      break;

    case '40': // IN TRANSITO
    case '50': // IN LAVORAZIONE
    case '60': // IN LAVORAZIONE - DOGANA
    case '90': // RITARDO
      info = {
        label: (
          <Trans i18nKey="tracking_page.status_info.90.label">
            Spedizione
            <span className={merchantStyleProps.style ? '' : `text-blue-500`} {...merchantStyleProps}>
              in transito
            </span>
          </Trans>
        ),
        type: StatusType.NORMAL,
        description: t('tracking_page.status_info.90.description')
      };
      break;
    case '70': // IN CONSEGNA
      info = {
        label: (
          <Trans i18nKey="tracking_page.status_info.70.label">
            Spedizione
            <span className={merchantStyleProps.style ? '' : `text-blue-500`} {...merchantStyleProps}>
              in consegna
            </span>
          </Trans>
        ),
        type: StatusType.NORMAL,
        description: t('tracking_page.status_info.70.description')
      };
      break;
    case '80': // TENTATIVO DI CONSEGNA FALLITO
      info = {
        label: (
          <Trans i18nKey="tracking_page.status_info.80.label">
            .<span className="text-yellow-500">Consegna fallita</span>
          </Trans>
        ),
        type: StatusType.PROBLEM_NOT_USER_SOLVABLE,
        description: t('tracking_page.status_info.80.description')
      };
      break;
    case '100': // ECCEZIONE
      info = {
        label: (
          <Trans i18nKey="tracking_page.status_info.100.label">
            C&apos;è un <span className="text-yellow-500">problema con la spedizione</span>
          </Trans>
        ),
        type: StatusType.PROBLEM_NOT_USER_SOLVABLE,
        description: t('tracking_page.status_info.100.description')
      };
      break;
    case '110': // ECCEZIONE - GIACENZA
      info = {
        label: (
          <Trans i18nKey="tracking_page.status_info.110.label">
            Spedizione in <span className="text-yellow-500">giacenza</span>
          </Trans>
        ),
        type: StatusType.PROBLEM_USER_SOLVABLE,
        description: t('tracking_page.status_info.110.description')
      };
      break;
    case '120': // ECCEZIONE - SPEDIZIONE IN RIENTRO / RIFIUTATA
      info = {
        label: (
          <Trans i18nKey="tracking_page.status_info.120.label">
            Spedizione <span className="text-yellow-500">in rientro / rifiutata</span>
          </Trans>
        ),
        type: StatusType.PROBLEM_NOT_SOLVABLE,
        description: t('tracking_page.status_info.120.description')
      };
      break;
    case '130': // ECCEZIONE - DANNEGGIAMENTO'
      info = {
        label: (
          <Trans i18nKey="tracking_page.status_info.130.label">
            Spedizione <span className="text-yellow-500">danneggiata</span>
          </Trans>
        ),
        type: StatusType.PROBLEM_NOT_USER_SOLVABLE,
        description: t('tracking_page.status_info.130.description')
      };
      break;
    case '140': // ECCEZIONE - SMARRIMENTO
      info = {
        label: (
          <Trans i18nKey="tracking_page.status_info.140.label">
            Spedizione <span className="text-yellow-500">smarrita</span>
          </Trans>
        ),
        type: StatusType.PROBLEM_NOT_USER_SOLVABLE,
        description: t('tracking_page.status_info.140.description')
      };
      break;
    case '150': // ECCEZIONE - CONSEGNA PARZIALE
      info = {
        label: (
          <Trans i18nKey="tracking_page.status_info.150.label">
            C&apos;è un <span className="text-yellow-500">problema con la spedizione</span>
          </Trans>
        ),
        type: StatusType.PROBLEM_NOT_USER_SOLVABLE,
        description: t('tracking_page.status_info.150.description')
      };
      break;
    case '160': // PUNTO DI RITIRO
      info = {
        label: (
          <Trans i18nKey="tracking_page.status_info.160.label">
            Spedizione
            <span className={merchantStyleProps.style ? '' : `text-green-500`} {...merchantStyleProps}>
              in un punto ritiro
            </span>
          </Trans>
        ),
        type: StatusType.NORMAL,
        description: t('tracking_page.status_info.160.description')
      };
      break;
    case '170': // RIENTRATA
      info = {
        label: (
          <Trans i18nKey="tracking_page.status_info.170.label">
            Spedizione
            <span className={merchantStyleProps.style ? '' : `text-green-500`} {...merchantStyleProps}>
              rientrata al mittente
            </span>
          </Trans>
        ),
        type: StatusType.DELIVERED_SUCCESSFULLY,
        description: t('tracking_page.status_info.170.description')
      };
      break;
    case '180': {
      // CONSEGNATA
      // @ts-expect-error TOFIX
      const postDeliveryAssistanceEndDate = endOfDay(addBusinessDays(parseDate(delivered_at), 3));

      info = {
        label: (
          <Trans i18nKey="tracking_page.status_info.180.label">
            Spedizione <span className="text-green-500">consegnata</span>
          </Trans>
        ),
        type: StatusType.DELIVERED_SUCCESSFULLY,
        // @ts-expect-error TOFIX
        deliveredDate: delivered_at && parseDate(delivered_at),
        deliveredDateHasTime: delivered_at ? delivered_at?.length > 11 : undefined,
        description: t('tracking_page.status_info.180.description'),
        canAskForAssistancePostDelivery: isBefore(new Date(), postDeliveryAssistanceEndDate),
        postDeliveryAssistanceEndDate
      };
      break;
    }
  }

  // managed.message has a value if the support operator wants the user to create a ticket
  // so we override `type` "user solvable" because that'so what allows creating the ticket on the frontend
  if (managed) {
    // @ts-expect-error TOFIX
    return { ...info, type: StatusType.PROBLEM_USER_SOLVABLE };
  }
  // @ts-expect-error TOFIX
  return info;
};
