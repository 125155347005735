import React from 'react';
import { useTranslation } from 'react-i18next';
import { DsButton } from '@spedire/spedire-design-system/lib';
import { Modal, ModalProps } from '../../components/modal';
import { EmailIcon, Remove } from '../../icons';
import { TrackingModals } from '../../pages/tracking/[trackingId]';

export const ExplanationModal: React.FC<
  ModalProps & { setActiveModal: React.Dispatch<React.SetStateAction<TrackingModals>>; obfuscatedEmail?: string }
> = ({ setActiveModal, obfuscatedEmail, ...modalProps }) => {
  const { t } = useTranslation();

  return (
    <Modal {...modalProps}>
      <div className="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
        <button
          type="button"
          className="text-gray-400 bg-white rounded-md hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500"
          onClick={modalProps.onClose}
        >
          <span className="sr-only">{t('screen_reader.close')}</span>
          <Remove className="w-6 h-6" aria-hidden="true" />
        </button>
      </div>

      <div className="text-base font-normal leading-6 text-gray-600">
        <h2 className="text-lg font-medium leading-6 text-gray-900">{t('ticket.explanation_modal.title')}</h2>
        <p className="mb-4">{t('ticket.explanation_modal.text_1')}</p>
        <p className="mb-4">S{t('ticket.explanation_modal.text_2')}</p>
        <p className="mb-2 text-sm font-medium leading-5 text-gray-700">
          {t('ticket.explanation_modal.data_provided_by_sender')}
        </p>
        <p className="mb-4 text-sm font-normal leading-5">
          <EmailIcon className="w-4 h-4 inline-block mr-2" />
          {obfuscatedEmail}
        </p>
        <p className="mb-4">{t('ticket.explanation_modal.text_3')}</p>
        <p className="mb-4">{t('ticket.explanation_modal.text_4')}</p>
      </div>
      <DsButton className="w-full" onClick={() => setActiveModal(TrackingModals.assistanceConfirmation)}>
        {t('ticket.explanation_modal.back')}
      </DsButton>
    </Modal>
  );
};
