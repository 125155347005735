import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { formatPlace } from '../../utils/placeUtils';
import { ShipmentModel } from './types';

interface IProps {
  trackingId: string;
  courierName: string;
  courierLogoUrl: string;
  courierTrackingUrl?: string;
  sender: ShipmentModel['sender'];
  recipient: ShipmentModel['recipient'];
}

export const InformativeBox: React.FC<IProps> = ({
  sender,
  recipient,
  trackingId,
  courierTrackingUrl,
  courierName,
  courierLogoUrl
}) => {
  const { t } = useTranslation();
  return (
    <div className="border border-gray-200 rounded-lg my-12 md:my-8">
      <div className="p-4 md:flex bg-gray-50 rounded-tr-lg rounded-tl-lg">
        <div className="md:w-1/2">
          <p className="text-gray-500 text-sm pr-3">{t('tracking.start_point')}</p>
          {/* <p className="text-gray-900 text-sm mt-2 pr-3">
          <strong>{senderName}</strong>
        </p> */}
          <p className="pr-3">{formatPlace(sender)}</p>
        </div>
        <div className="mt-8 md:mt-0 md:w-1/2">
          <p className="text-gray-500 text-sm pr-3">{t('tracking.destination_point')}</p>
          {/* <p className="text-gray-900 text-sm mt-2 pr-3">
          <strong>{recipientName}</strong>
        </p> */}
          <p className="pr-3">{formatPlace(recipient)}</p>
        </div>
      </div>
      {/*  */}
      <div className="px-6 py-3">
        <p className="text-center text-xs leading-4 tracking-wider uppercase">
          <span className="font-semibold text-gray-500 md:text-gray-900">{t('tracking_page.shippedWith')}</span>{' '}
          <span className="block mt-2 md:hidden" />
          <a href={courierTrackingUrl} target="_blank" rel="noreferrer">
            <img className="inline-block md:ml-4 mr-2 h-8" src={courierLogoUrl} alt="logo corriere" />
            <span
              className={classNames('font-semibold', {
                underline: courierTrackingUrl
              })}
            >
              {courierName}
            </span>
            <span
              className={classNames('text-gray-400 ml-1', {
                underline: courierTrackingUrl
              })}
            >
              ({trackingId})
            </span>
          </a>
        </p>
      </div>
    </div>
  );
};
