import { HandIcon, CodeIcon } from '@heroicons/react/solid';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Markdown } from '../../components/markdown';

export const ShipmentNotFound: React.FC<{
  trackingId: string;
}> = ({ trackingId }) => {
  const { t } = useTranslation();
  return (
    <div className="py-28 md:py-56">
      <div className="px-4 md:px-8 lg:px-0">
        <h1 className="text-3xl font-extrabold text-gray-900 tracking-tight sm:text-4xl mb-8">
          <Trans i18nKey={'tracking_page.notFoundSection.title'}>
            placeholder<span className="text-red-500">placeholder</span>placeholder
          </Trans>
        </h1>
        <div className="prose text-base leading-6 font-normal text-gray-600">
          <Markdown>{t('tracking_page.notFoundSection.text1')}</Markdown>
        </div>
        <p className="bg-white shadow rounded-sm px-4 py-2 mt-3 tracking-widestxl text-2xl text-gray-600">
          {trackingId}
        </p>

        <div className="mt-8 flex flex-wrap">
          {/* TODO: icon to replace with new pack */}
          <HandIcon className="h-5 mr-2 mt-0.5" />
          <div className="prose text-base leading-6 font-normal text-gray-600">
            <Markdown>{t('tracking_page.notFoundSection.text2')}</Markdown>
          </div>
        </div>
        <div className="mt-2 flex flex-wrap">
          {/* TODO: icon to replace with new pack */}
          <CodeIcon className="h-5 mr-2 mt-0.5 align-text-top" />
          <div className="prose text-base leading-6 font-normal text-gray-600">
            <Markdown>{t('tracking_page.notFoundSection.text3')}</Markdown>
          </div>
        </div>
      </div>
    </div>
  );
};
