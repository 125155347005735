import format from 'date-fns/format';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { TicketDetail } from '../../features/ticket/ticketApi';
import { ArrowRight, CheckIcon } from '../../icons';
import { parseDate } from '../../utils/parseDate';
import { dateFNSMappedLocales, customLanguageDetector } from '../../strings/setup';

export const ClosedTicketBox: React.FC<{ ticket: TicketDetail }> = ({ ticket }) => {
  const { t } = useTranslation();

  return (
    <a
      href={`/ticket/${ticket.id}`}
      className="flex flex-row items-center justify-between max-w-3xl p-4 mx-auto mb-2 bg-white border border-gray-200 rounded-md shadow-sm text-sm"
    >
      <p>
        {t('ticket.closed_ticket_box.ticket_of_day_x', {
          day: format(parseDate(ticket.created_at), 'd MMMM', {
            locale: dateFNSMappedLocales[customLanguageDetector()]
          })
        })}
      </p>
      <div className="flex flex-row items-center ">
        <CheckIcon className="w-3 h-3 mr-3 text-green-500" />{' '}
        <span className="text-green-700 font-medium">{ticket.status.name}</span> <ArrowRight className="w-3 h-3 ml-3" />
      </div>
    </a>
  );
};
