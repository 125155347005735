import classNames from 'classnames';
import React from 'react';

const COLORS = {
  blue: { main: 'bg-blue-50 text-blue-800 prose-blue', icon: 'text-blue-400' },
  orange: { main: 'bg-yellow-50 text-yellow-800 prose-yellow', icon: 'text-yellow-400' }
};

export const InfoBox: React.FC<{ color: 'blue' | 'orange'; icon?: JSX.Element; className?: string }> = ({
  children,
  color,
  icon,
  className
}) => {
  const colors = COLORS[color];

  return (
    <div className={classNames(colors.main, 'p-4 rounded-md', 'text-sm leading-5', 'flex flex-row', className)}>
      {icon && <div className={classNames(colors.icon, 'w-5 h-5 mr-3')}>{icon}</div>}
      <div className="w-full">{children}</div>
    </div>
  );
};
