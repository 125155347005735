import React from 'react';
import { useTranslation } from 'react-i18next';
import { DsLinkButton } from '@spedire/spedire-design-system';
import { Markdown } from '../../components/markdown';
import { TicketDetail } from '../../features/ticket/ticketApi';

import defaultAvatar from '../../images/default-avatar.png';

const BlueParagraph = ({ children, ...props }) => (
  <b className="text-blue-600" {...props}>
    {children}
  </b>
);

export const OpenTicketBox: React.FC<{ ticket: TicketDetail }> = ({ ticket }) => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-row items-center justify-between max-w-3xl p-4 mx-auto mb-2 bg-white border border-gray-200 rounded-md shadow-sm text-sm">
      <div className="flex flex-row items-center justify-between">
        <img src={ticket?.assignee?.avatar_url || defaultAvatar} className="w-10 mr-4" />
        {ticket.assignee ? (
          <div className="flex flex-row items-center">
            <div className="flex-shrink">
              <Markdown
                components={{
                  strong: BlueParagraph
                }}
              >
                {t('ticket.open_ticket_box.youre_already_talking_with', { name: ticket.assignee.name })}
              </Markdown>
            </div>
          </div>
        ) : (
          <Markdown
            components={{
              strong: BlueParagraph
            }}
          >
            {t('ticket.open_ticket_box.youre_already_talking_with_the_team')}
          </Markdown>
        )}
      </div>
      <div className="flex-shrink-0">
        <DsLinkButton href={`/ticket/${ticket.id}`}> {t('ticket.open_ticket_box.button')}</DsLinkButton>
      </div>
    </div>
  );
};
