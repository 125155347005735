import classNames from 'classnames';
import React from 'react';
import { Controller, RefCallBack, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Input, Label, RequiredFieldIndicator } from '@spedire/spedire-design-system';

type InputFieldProps = React.ComponentProps<typeof Input> & {
  className?: string;
  label: string;
  name: string;
  required?: boolean;
  description?: string;
  hint?: React.ReactNode;
  render?: React.ComponentProps<typeof Controller>['render'];
  /**
   * avoid passing ref the render function, this is needed for controlled inputs that don't take refs
   * to avoid the red warning in the console. Ex: Dropdown
   */
  noRef?: boolean;
};
export const InputField: React.FC<InputFieldProps> = ({
  className,
  label,
  name,
  required,
  description,
  hint,
  render,
  noRef,
  ...props
}) => {
  const {
    register,
    formState: { errors }
  } = useFormContext();
  const { t } = useTranslation();
  return (
    <div className={classNames('mb-4', className)}>
      <div className="flex flex-row justify-between">
        <Label htmlFor={name}>
          {label}
          {required && <RequiredFieldIndicator />}
        </Label>
        <div>{hint}</div>
      </div>
      {render ? (
        <Controller
          name={name}
          render={
            noRef
              ? ({ field, ...props }) =>
                  // ugly fix for the "cannot pass ref to function components"
                  render({ field: { ...field, ref: undefined as unknown as RefCallBack }, ...props })
              : render
          }
        />
      ) : (
        <Input
          type="text"
          id={name}
          {...register(name, { required: required && t('form.validations.required') })}
          {...props}
          status={errors[name]?.message ? 'error' : undefined}
        />
      )}
      {description && <p className="text-gray-500 text-sm leading-5 font-normal mt-2">{description}</p>}
      <p className="text-red-500 mt-2">{errors[name]?.message}</p>
    </div>
  );
};
