import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { DsButton } from '@spedire/spedire-design-system/lib';
import { Modal, ModalProps } from '../../components/modal';
import { logout, userSelector } from '../../features/auth';
import { Remove } from '../../icons';
import { TrackingModals } from '../../pages/tracking/[trackingId]';

export const WrongAccountModal: React.FC<
  ModalProps & { setActiveModal: React.Dispatch<React.SetStateAction<TrackingModals | null>> }
> = ({ setActiveModal, ...modalProps }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const user = useSelector(userSelector);

  return (
    <Modal {...modalProps}>
      <div className="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
        <button
          type="button"
          className="text-gray-400 bg-white rounded-md hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500"
          onClick={modalProps.onClose}
        >
          <span className="sr-only">{t('screen_reader.close')}</span>
          <Remove className="w-6 h-6" aria-hidden="true" />
        </button>
      </div>
      <div className="text-base font-normal leading-6 text-gray-600">
        <h2 className="text-lg font-medium leading-6 text-gray-900 mb-1">{t('ticket.wrong_account_modal.title')}</h2>
        <p className="mb-4">{t('ticket.wrong_account_modal.text_1')}</p>
        <p className="mb-6">{t('ticket.wrong_account_modal.text_2', { email: user?.email })} </p>
      </div>
      <DsButton
        styleType="primary"
        className="w-full mb-2"
        onClick={() => {
          dispatch(logout());
          setActiveModal(TrackingModals.signUp);
        }}
      >
        {t('ticket.wrong_account_modal.logout')}
      </DsButton>

      <DsButton styleType="secondary" className="w-full" onClick={() => setActiveModal(null)}>
        {t('ticket.wrong_account_modal.back')}
      </DsButton>
    </Modal>
  );
};
